import React, { useState } from 'react';
import { PortableText } from '@portabletext/react';
import { Await, Link, useAsyncValue, useLoaderData } from "react-router-dom";
import { Helmet } from 'react-helmet-async';
import LoadedPage from '../components/LoadedPage';
import sanityClient from '../client.js';
import imageUrlBuilder from '@sanity/image-url';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css'
import replaceLastSpaceWithNbsp from '../components/replaceLastSpaceWithNbsp';

const builder = imageUrlBuilder(sanityClient);
function urlFor(source) {
    return builder.image(source)
}

const myPortableTextComponents = {
    block: {
        normal: ({ children }) => <p>{replaceLastSpaceWithNbsp(children)}</p>,
    },
}

function Book(props) {
    const book = props.book;
    const [loading, setLoading] = useState(true);

    function onLoad() {
        setLoading(false);
    }

    return (
        <section className="bg-gray-100 -mx-5 sm:-mx-8 my-5 px-5 sm:px-8 py-6 sm:rounded shadow-sm">
            <div className="flex flex-col sm:flex-row items-center sm:items-start">
                {loading &&
                    <div className="rounded shadow-md max-w-[14rem] sm:max-w-[15rem]">
                        <Skeleton height={360} width={240} />
                    </div>
                }
                <Link to={`/books/${book.slug.current}`}>
                    <img src={urlFor(book.mainImage).url()} alt={book.title} className={`rounded shadow-md max-w-[14rem] sm:max-w-[15rem] loadingImage ${loading ? '' : 'loaded'}`} onLoad={onLoad} />
                </Link>
                <div className="pt-5 sm:pt-0 sm:pl-8 flex-1">
                    <h2 className="text-3xl font-bold">{book.title}</h2>
                    <h3 className="text-xl font-semibold text-gray-700 leading-[1.15] pt-[0.2rem]">{replaceLastSpaceWithNbsp(book.subTitle) || (<em>{replaceLastSpaceWithNbsp(book.titleWork)}</em>)}</h3>
                    <div className="pt-4"><PortableText value={book.description} components={myPortableTextComponents} /></div>
                    <div className="flex justify-end">
                        <Link to={`/books/${book.slug.current}`} className="mt-6 py-2 px-5 sm:py-3 sm:px-6 shadow-sm rounded font-semibold text-center text-gray-600 rounded bg-white border-gray-400/50 border-2 hover:bg-gray-200/75 focus:outline-0 focus:ring focus:ring-indigo-200 focus:ring-opacity-50 focus:outline-none">More Details</Link>
                    </div>
                </div>
            </div>
        </section>
    )
}

function MyFunc() {
    const {books, bookChapters} = useAsyncValue();

    return (
        <LoadedPage title="Books">
            <div className='-mt-[0.75rem]'>
                {books.map((book, index) => {
                    return (
                        <Book book={book} key={book.slug.current} />
                    )
                })}
            </div>

            <h2 className='pt-12 md:pt-14 bg-white font-display text-[1.5rem] sm:text-[1.6rem] md:text-[1.75rem] lg:text-[1.85rem] xl:text-[1.95rem] text-gray-800 font-semibold pb-3 text-center'>Chapters in Edited Collections</h2>
            <div className='-mt-[0.75rem]'>
                {bookChapters.map((book, index) => {
                    console.log(book)
                    return (
                        <Book book={book} key={book.slug.current} />
                    )
                })}
            </div>
        </LoadedPage>
    )
}

export default function Books() {
    const data = useLoaderData();

    return (
        <>
            <Helmet>
                <title>Books - Audrey Wu Clark</title>
            </Helmet>

            <React.Suspense
                fallback={<></>}
            >
                <Await
                    resolve={data.books}
                    errorElement={
                        <p>Error loading books!</p>
                    }
                >
                    <MyFunc />
                </Await>
            </React.Suspense>
        </>
    );
};
