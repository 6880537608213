import { useEffect, useRef } from "react";
import Navbar from "../components/Navbar";
import { Outlet, ScrollRestoration } from "react-router-dom";
import _ from 'lodash';
import { Helmet, HelmetProvider } from "react-helmet-async";
import { SkeletonTheme } from "react-loading-skeleton";

export default function Root() {
    const today = new Date();

    useEffect(() => {
        const documentHeight = () => {
            const doc = document.documentElement
            doc.style.setProperty('--doc-height', `${window.innerHeight}px`)
        }
        window.addEventListener("resize", _.debounce(() => {
            documentHeight();
        }, 400))
        documentHeight()
        return () => window.removeEventListener('resize', _.debounce(() => {
            documentHeight();
        }, 400));
    }, [])

    const colors = require('tailwindcss/colors')

    const ref2 = useRef(null)
    const ref3 = useRef(null)

    return (
        <HelmetProvider>
            <Helmet>
                <title>Audrey Wu Clark</title>
            </Helmet>
            <SkeletonTheme baseColor={colors.gray[200]} highlightColor={colors.gray[100]}>
                <div className="font-serif text-gray-900 flex flex-col wrapper overflow-x-hidden">
                    <Navbar ref2={ref2} ref3={ref3} />
                    <main className="container mx-auto px-5 xl:px-20 2xl:px-36 grow flex">
                        <div className="lg:mx-[6rem] xl:mx-[8rem] 2xl:mx-[10rem] flex-1 w-full md:px-[1.5rem]">
                            <Outlet context={[ref2, ref3]} />
                        </div>
                    </main>
                    <footer className="page-footer grow-0 shrink-0 container mx-auto px-5 xl:px-20 2xl:px-32 bg-white">
                        <hr className="-mx-5" />
                        <span className="block text-xs sm:text-sm text-gray-500 text-center my-6">© {today.getFullYear()} Audrey Wu Clark. All Rights Reserved.</span>
                    </footer>
                </div>
            </SkeletonTheme>
            <ScrollRestoration />
        </HelmetProvider>
    );
}
