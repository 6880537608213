import { Await, Link, useAsyncValue, useLoaderData } from "react-router-dom";
import LoadedPage from "../components/LoadedPage";
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css'
import React, { useState } from "react";
import sanityClient from '../client.js';
import imageUrlBuilder from '@sanity/image-url';
import { PortableText } from "@portabletext/react";
import replaceLastSpaceWithNbsp from '../components/replaceLastSpaceWithNbsp';

const builder = imageUrlBuilder(sanityClient);
function urlFor(source) {
  return builder.image(source)
}

const myPortableTextComponents = {
  block: {
    normal: ({ children }) => <p>{replaceLastSpaceWithNbsp(children)}</p>,
  },
}

function MyFunc() {
  const [loading, setLoading] = useState({ main: true, cover1: true, cover2: true });
  const { books, pageText, authorPhoto } = useAsyncValue();

  return (
    <LoadedPage>
      <section className="flex flex-col">
        {loading.main &&
          <Skeleton height={464} />
        }
        <div className="homeAuthorImage">
          {/* <img src={urlFor(authorPhoto[0].image).url()} alt={authorPhoto[0].name} className={`loadingImage ${loading.main ? '' : 'loadedPart'} ambientEffect`} /> */}
          <img src={urlFor(authorPhoto[0].image).url()} alt={authorPhoto[0].name} className={`rounded-xl shadow-md loadingImage ${loading.main ? '' : 'loaded'} mainImage`} onLoad={() => setLoading(l => ({ ...l, main: false }))} />
        </div>
        <div className="mt-5"><PortableText value={pageText[0].text} /></div>
        <div className="flex flex-row-reverse justify-center flex-wrap sm:justify-start mt-2 sm:mt-1">
          <Link to="/about" className="mt-4 sm:mt-6 mx-3 sm:mr-0 py-2 px-5 sm:py-3 sm:px-6 shadow rounded font-semibold text-center text-white rounded bg-[#940025] border-[#940025] border-2 hover:bg-[#7a001f] hover:border-[#7a001f] focus:outline-0 focus:ring focus:ring-[#ef8fa7] focus:ring-opacity-50 focus:outline-none flex">Learn More</Link>
          <Link to="/contact" className="mt-4 sm:mt-6 py-2 px-5 sm:py-3 sm:px-6 shadow-sm rounded font-semibold text-center text-gray-600 rounded bg-white border-gray-400/50 border-2 hover:bg-gray-200/75 focus:ring focus:ring-indigo-200 focus:ring-opacity-50 focus:outline-none">Get in Touch</Link>
        </div>
      </section>
      <section className="bg-gray-100 -mx-5 sm:-mx-8 mt-20 px-5 sm:px-8 py-6 sm:rounded shadow-sm">
        <div className="flex items-center mb-4">
          <h2 className="font-bold text-gray-500 text-lg italic">Latest Book</h2>
          <hr className="flex-1 ml-3 border-gray-300" />
        </div>
        <div className="flex flex-col sm:flex-row items-center sm:items-start">
          {loading.cover1 &&
            <div className="rounded shadow-md max-w-[14rem] sm:max-w-[15rem]">
              <Skeleton height={360} width={240} />
            </div>
          }
          <Link to={`/books/${books[0].slug.current}`}>
            <img src={urlFor(books[0].mainImage).url()} alt={`Book cover of ${books[0].title}`} className={`rounded shadow-md max-w-[14rem] sm:max-w-[15rem] loadingImage ${loading.cover1 ? '' : 'loaded'}`} onLoad={() => setLoading(l => ({ ...l, cover1: false }))} />
          </Link>
          <div className="pt-5 sm:pt-0 sm:pl-8 flex-1">
            <h2 className="text-3xl font-bold">{books[0].title}</h2>
            <h3 className="text-xl font-semibold text-gray-800 leading-[1.15]">{books[0].subTitle}</h3>
            <div className="pt-4"><PortableText value={books[0].description} components={myPortableTextComponents} /></div>
            <div className="flex justify-end">
              <Link to={`/books/${books[0].slug.current}`} className="mt-6 py-2 px-5 sm:py-3 sm:px-6 shadow-sm rounded font-semibold text-center text-gray-600 rounded bg-white border-gray-400/50 border-2 hover:bg-gray-200/75 focus:outline-0 focus:ring focus:ring-indigo-200 focus:ring-opacity-50 focus:outline-none">More Details</Link>
            </div>
          </div>
        </div>
        <hr className="mt-7 border-gray-300" />
        <div className="flex justify-center items-center flex-wrap mb-1">
          {loading.cover2 &&
            <div className="mt-6 rounded shadow-md h-44 mx-2 cursor-pointer">
              <Skeleton height={176} width={117.333} />
            </div>
          }
          {books.slice(1).map((book, index) => {
            return (
              <Link to={`/books/${books[index+1].slug.current}`} key={book.slug.current}>
                <img src={urlFor(books[index+1].mainImage).url()} alt={`Book cover of ${books[index+1].title}`} className={`mt-6 rounded shadow-md h-44 mx-3 cursor-pointer loadingImage ${loading.cover2 ? '' : 'loaded'}`} onLoad={() => setLoading(l => ({ ...l, cover2: false }))} />
              </Link>
            )
          })}
          <Link to="/books" className="mt-6 mx-3 sm:mr-0 py-2 px-5 sm:py-3 sm:px-6 shadow rounded font-semibold text-center text-white rounded bg-[#940025] border-[#940025] border-2 hover:bg-[#7a001f] hover:border-[#7a001f] focus:outline-0 focus:ring focus:ring-[#ef8fa7] focus:ring-opacity-50 focus:outline-none">See All Books</Link>
        </div>
      </section>
    </LoadedPage>
  )
}

export default function Home() {
  const data = useLoaderData();

  return (
    <React.Suspense
      fallback={<></>}
    >
      <Await
        resolve={data.all}
        errorElement={
          <p>Error loading!</p>
        }
      >
        <MyFunc />
      </Await>
    </React.Suspense>
  );
}
