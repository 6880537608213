export default function replaceLastSpaceWithNbsp(input) {
    if (typeof input === 'string') {
        const lastSpaceIndex = input.lastIndexOf(' ');

        if (lastSpaceIndex !== -1) {
            const modifiedString =
                input.substring(0, lastSpaceIndex) +
                '\u00A0' +
                input.substring(lastSpaceIndex + 1);
            return modifiedString;
        }
    } else if (Array.isArray(input) && input.length > 0) {
        const arr = [...input];
        const lastString = arr[arr.length - 1];
        const lastSpaceIndex = lastString.lastIndexOf(' ');

        if (lastSpaceIndex !== -1) {
            const modifiedString =
                lastString.substring(0, lastSpaceIndex) +
                '\u00A0' +
                lastString.substring(lastSpaceIndex + 1);
            arr[arr.length - 1] = modifiedString;
        }

        return arr;
    }

    return input;
}
