import React, { useState } from "react";
import { Helmet } from "react-helmet-async";
import { Await, Link, useAsyncValue, useLoaderData } from "react-router-dom";
import LoadedPage from "../components/LoadedPage";
import imageUrlBuilder from '@sanity/image-url';
import sanityClient from '../client.js';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css'
import { PortableText } from '@portabletext/react';
import { HiOutlineExternalLink } from "react-icons/hi";
import replaceLastSpaceWithNbsp from "../components/replaceLastSpaceWithNbsp";

const builder = imageUrlBuilder(sanityClient);
function urlFor(source) {
    return builder.image(source)
}

const myPortableTextComponents = {
    block: {
      normal: ({ children }) => <p>{replaceLastSpaceWithNbsp(children)}</p>,
    },
}

function MyFunc() {
    const book = useAsyncValue()[0];
    const [loading, setLoading] = useState(true);

    function onLoad() {
        setLoading(false);
    }

    console.log(book)

    return (
        <LoadedPage>
            <Helmet>
                <title>{book.title} - Audrey Wu Clark</title>
            </Helmet>
            <Link to="/books" className="flex items-center text-sm text-gray-700 hover:underline mb-3">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" className="w-4 h-4 mr-1">
                    <path fillRule="evenodd" d="M17 10a.75.75 0 01-.75.75H5.612l4.158 3.96a.75.75 0 11-1.04 1.08l-5.5-5.25a.75.75 0 010-1.08l5.5-5.25a.75.75 0 111.04 1.08L5.612 9.25H16.25A.75.75 0 0117 10z" clipRule="evenodd" />
                </svg>
                <span>See All Books</span>
            </Link>

            <div className="bg-gray-100 -mx-5 sm:rounded shadow-sm p-5 mb-[5rem] flex flex-col md:flex-row items-center md:items-end">
                <div className="mb-10 md:mb-0 md:translate-y-[5rem] md:-mt-[5rem]">
                    {loading &&
                        <div className="rounded shadow-md max-w-[16rem] sm:max-w-[17rem]">
                            <Skeleton height={408} width={272} />
                        </div>
                    }
                    <img src={urlFor(book.mainImage).url()} alt={book.title} className={`rounded shadow-md max-w-[16rem] sm:max-w-[17rem] loadingImage ${loading ? '' : 'loaded'}`} onLoad={onLoad} />
                </div>
                <div className="md:pl-7 text-center md:text-left">
                    <h1 className="text-4xl font-bold pb-1.5">{book.title}</h1>
                    <h2 className="text-2xl font-semibold text-gray-600 leading-[1.15] pb-7">{replaceLastSpaceWithNbsp(book.subTitle) || (<em>{replaceLastSpaceWithNbsp(book.titleWork)}</em>)}</h2>
                    <p className="pb-[0.3rem] leading-[1.2]"><span className="font-bold">Publisher:</span> {book.publisher}</p>
                    <p className="pb-[1rem]"><span className="font-bold">Publication Date:</span> {book.publicationDate}</p>
                    <a href={book.purchaseLink} target="_blank" rel="noreferrer" className="inline-block py-2 px-4 sm:py-3 sm:px-5 rounded font-semibold text-center text-white rounded bg-indigo-600 border-indigo-600 border-2 hover:bg-indigo-700 hover:border-indigo-700 focus:outline-0 focus:ring focus:ring-indigo-300 focus:ring-opacity-50 focus:outline-none shadow-md mx-auto">
                        <HiOutlineExternalLink className="inline -mt-1 bg-indigo-300 bookPurchaseIcon p-1 box-content rounded-full ring ring-indigo-400 ring-opacity-50" />
                        <span className="pl-3">Purchase the Book</span>
                    </a>
                </div>
            </div>

            <div className="-mt-10 md:pt-12 max-w-3xl mx-auto"><PortableText value={book.description} components={myPortableTextComponents} /></div>
        </LoadedPage>
    )
}

export default function BookDetails() {
    const data = useLoaderData();

    return (
        <>
            <Helmet>
                <title>Books - Audrey Wu Clark</title>
            </Helmet>

            <React.Suspense
                fallback={<></>}
            >
                <Await
                    resolve={data.book}
                    errorElement={
                        <p>Error loading book!</p>
                    }
                >
                    <MyFunc />
                </Await>
            </React.Suspense>
        </>
    )
}