import 'react-app-polyfill/ie9';
import 'react-app-polyfill/stable';
import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import Home from './routes/Home';
import About from './routes/About';
import Books from './routes/Books';
import Contact from './routes/Contact';
import Error from './routes/Error';
import reportWebVitals from './reportWebVitals';
import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";
import Root from './routes/Root';
import {
  defer,
} from "react-router-dom";
import sanityClient from './client.js';
import Page from './components/Page';
import BookDetails from './routes/BookDetails';

const router = createBrowserRouter([
  {
    element: <Root />,
    children: [
      {
        index: true,
        element: <Page component={<Home />} />,
        loader: async () => {
          const promise = sanityClient.fetch(`{
            'books': *[_type == "book"] | order(publicationDate desc) {
              title,
              subTitle,
              slug,
              description,
              mainImage
            },
            'pageText': *[_type == "pageText" && slug.current == "short-bio"] {
              text
            },
            'authorPhoto': *[_type == "authorPhoto" && slug.current == "home-main"]{
              name,
              slug,
              image
            },
          }`)

          return defer({
            all: promise,
          });
        },
      },
      {
        path: "/about",
        element: <Page component={<About />} />,
        loader: async () => {
          const promise = sanityClient.fetch(`{
            'pageText': *[_type == "pageText" && slug.current == "longer-bio"] {
              text
            },
            'education': *[_type == "education"] | order(endYear desc) {
              school,
              slug,
              startYear,
              endYear,
              degree,
              details
            },
            'publications': *[_type == "publications" && slug.current == "batch"]{
              books,
              reviews
            },
            'teaching': *[_type == "teachingLocation"] | order(_createdAt desc) {
              _id, location,
              "positions": *[_type == "teachingPosition" && references(^._id)]
            },
            'peerReviewedPresentations': *[_type == "peerReviewedPresentations" && slug.current == "batch"] {
              text
            },
            'researchAwardsHonors': *[_type == "researchAwardsHonors" && slug.current == "batch"] {
              text
            },
            'archivesConsulted': *[_type == "archivesConsulted" && slug.current == "batch"] {
              text
            },
            'serviceOrder': *[_type == "serviceOrder" && slug.current == "batch"] {
              'service': service[]->,
            },
            'otherAcademicPositions': *[_type == "otherAcademicPositions" && slug.current == "batch"] {
              text
            },
            'professionalAffiliations': *[_type == "professionalAffiliations" && slug.current == "batch"] {
              text
            },
            'languages': *[_type == "languages" && slug.current == "batch"] {
              text
            },
          }`)

          return defer({
            all: promise,
          });
        },
      },
      {
        path: "/books",
        element: <Page component={<Books />} />,
        loader: async () => {
          const booksPromise = sanityClient.fetch(`{
            'books': *[_type == "book"] | order(_createdAt desc) {
              title,
              subTitle,
              titleWork,
              slug,
              description,
              mainImage,
              publisher,
              publicationDate,
              purchaseLink
            },
            'bookChapters': *[_type == "bookChapter"] | order(_createdAt desc) {
              title,
              subTitle,
              titleWork,
              slug,
              description,
              mainImage,
              publisher,
              publicationDate,
              purchaseLink
            }
          }`)

          return defer({
            books: booksPromise,
          });
        },
      },
      {
        path: "/books/:slug",
        element: <Page component={<BookDetails />} />,
        loader: async ({ params }) => {
          const booksPromise = sanityClient.fetch(`*[(_type == "book" || _type == "bookChapter") && slug.current == "${params.slug}"]{
            title,
            subTitle,
            titleWork,
            slug,
            description,
            mainImage,
            publisher,
            publicationDate,
            purchaseLink
          }`)

          return defer({
            book: booksPromise,
          });
        },
      },
      {
        path: "/contact",
        element: <Page component={<Contact />} />,
        loader: async () => {
          const pageTextPromise = sanityClient.fetch(`*[_type == "pageText" && slug.current == "contact-invitation"] {
            text
          }`)

          return defer({
            pageText: pageTextPromise,
          });
        },
      },
      {
        path: "*",
        element: <Page component={<Error />} />,
      },
    ],
  },
]);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
