export default function LoadedPage(props) {
    return (
        <>
            <div className='testing fade h-[99.99%] bg-white pb-20'>
                {props.title &&
                    <>
                        <h1 className='pt-10 md:pt-12 bg-white font-display text-[1.75rem] sm:text-[1.85rem] md:text-[2rem] lg:text-[2.1rem] xl:text-[2.2rem] text-gray-800 font-semibold pb-3 text-center'>
                            {props.title}
                        </h1>
                        {props.children}
                    </>
                }
                {!props.title &&
                    <div className='pt-10'>
                        {props.children}
                    </div>
                }
            </div>
        </>
    )
}