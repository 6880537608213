import { Link } from "react-router-dom";
import LoadedPage from "../components/LoadedPage";
import { GrHomeRounded } from "react-icons/gr";
import { Helmet } from "react-helmet-async";

export default function Error() {
    return (
        <>
            <Helmet>
                <title>404 Error - Audrey Wu Clark</title>
            </Helmet>
            <LoadedPage>
                <div className="text-center -mt-[4rem] md:-mt-[6rem]">
                    <h1 className="font-bold text-gray-300 text-[10rem] tracking-[-0.5rem] sm:text-[15rem] md:text-[20rem] md:tracking-[-0.75rem] xl:text-[25rem]">
                        404
                    </h1>
                    <div className="bg-gray-100 rounded py-[4rem] translate-y-[-3.9rem] mb-[-3.9rem] sm:translate-y-[-5.8rem] sm:mb-[-5.8rem] md:translate-y-[-8rem] md:mb-[-8rem] xl:translate-y-[-10rem] xl:mb-[-10rem] px-4">
                        <p className="font-bold leading-[1] tracking-[-0.04rem] pb-[0.75rem] text-[1.8rem] sm:text-[2rem] md:text-[2.25rem] xl:text-[2.5rem">Page Not Found</p>
                        <p className="pb-[3rem]">We're sorry, but the page you requested could not be&nbsp;found.</p>
                        <div className="flex">
                            <Link to="/" className="py-2 px-5 sm:py-3 sm:px-6 rounded font-semibold text-center text-white rounded bg-[#940025] border-[#940025] border-2 hover:bg-[#7a001f] hover:border-[#7a001f] focus:outline-0 focus:ring focus:ring-[#ef8fa7] focus:ring-opacity-50 focus:outline-none shadow flex items-center mx-auto">
                                <GrHomeRounded className='contactSendIcon mr-3' />
                                Return Home
                            </Link>
                        </div>
                    </div>
                </div>
            </LoadedPage>
        </>
    )
}