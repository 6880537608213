import React, { useRef } from 'react';
import emailjs from '@emailjs/browser';
import { Helmet } from 'react-helmet-async';
import LoadedPage from '../components/LoadedPage';
import { Await, useLoaderData } from 'react-router-dom';
import { PortableText } from '@portabletext/react';
import { GrSend } from "react-icons/gr";
import { BiError } from "react-icons/bi";

export default function Contact() {
    const data = useLoaderData();
    const form = useRef();

    function resetToast() {
        document.getElementById("toast-success").style.display = "none";
        document.getElementById("toast-error").style.display = "none";
    }

    const sendEmail = (e) => {
        e.preventDefault();
        document.getElementById("form-submit").classList.add('disabledSubmit');

        emailjs.sendForm('service_pu76lti', 'template_19dsbew', form.current, 'Pvm7LKOpFqimkpuRM')
            .then((result) => {
                console.log(result.text);
                form.current.reset();
                document.getElementById("toast-success").style.display = "block";
                document.getElementById("form-submit").classList.remove('disabledSubmit');
            }, (error) => {
                console.log(error.text);
                document.getElementById("toast-error").style.display = "block";
                document.getElementById("form-submit").classList.remove('disabledSubmit');
            });
    };

    const myPortableTextComponents = {
        marks: {
            link: ({ value, children }) => {
                const { href } = value
                return <a href={href} target="_blank" rel="noreferrer" className='text-[#940025] underline'>{children}</a>
            }
        }
    }

    return (
        <>
            <Helmet>
                <title>Contact - Audrey Wu Clark</title>
            </Helmet>
            <React.Suspense
                fallback={<></>}
            >
                <Await
                    resolve={data.pageText}
                    errorElement={
                        <p>Error loading about!</p>
                    }
                >
                    {(pageText) => (
                        <>
                            <LoadedPage title="Contact">
                                <section>
                                    <div className="sm:px-6 mx-auto max-w-screen-md">
                                        <div className='mb-8'><PortableText value={pageText[0].text} components={myPortableTextComponents} /></div>
                                        <form ref={form} onSubmit={sendEmail} className="space-y-5 pt-2">
                                            <div>
                                                <label htmlFor="name" className="block mb-1 font-semibold text-gray-700">Name</label>
                                                <input type="text" id="name" name="from_name" className="block py-2 sm:p-3 w-full bg-gray-50 rounded border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50" required />
                                            </div>
                                            <div>
                                                <label htmlFor="email" className="block mb-1 font-semibold text-gray-700">Email Address</label>
                                                <input type="email" id="email" name="reply_to" className="block py-2 sm:p-3 w-full bg-gray-50 rounded border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50" required />
                                            </div>
                                            <div className="sm:col-span-2">
                                                <label htmlFor="message" className="block mb-1 font-semibold text-gray-700">Message</label>
                                                <textarea id="message" name="message" rows="6" className="block py-2 sm:p-3 w-full bg-gray-50 rounded border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50" required></textarea>
                                            </div>
                                            <button id="form-submit" type="submit" className="py-2 px-5 sm:py-3 sm:px-6 rounded font-semibold text-center text-white rounded bg-[#940025] border-[#940025] border-2 hover:bg-[#7a001f] hover:border-[#7a001f] focus:outline-0 focus:ring focus:ring-[#ef8fa7] focus:ring-opacity-50 focus:outline-none shadow flex items-center mx-auto disabled:bg-blue" onClick={resetToast}><GrSend className='contactSendIcon mr-3' /> Send Message</button>
                                        </form>
                                    </div>
                                </section>
                            </LoadedPage>

                            <div id="toast-error" className="hidden w-full max-w-sm px-5 fixed bottom-5 left-0" role="alert">
                                <div className="flex items-center text-gray-700 bg-red-100 rounded-lg shadow p-4">
                                    <div className="inline-flex items-center justify-center flex-shrink-0 w-6 h-8 text-red-500 bg-red-100 rounded-lg">
                                        <BiError />
                                    </div>
                                    <div className="mx-3 text-sm font-normal">Message failed to send.</div>
                                    <button type="button" onClick={() => document.getElementById("toast-error").style.display = "none"} className="ml-auto -mx-1.5 -my-1.5 bg-white text-gray-400 hover:text-gray-900 rounded-lg focus:ring-2 focus:ring-gray-300 p-1.5 hover:bg-gray-100 inline-flex h-8 w-8" data-dismiss-target="#toast-danger" aria-label="Close">
                                        <span className="sr-only">Close</span>
                                        <svg aria-hidden="true" className="w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clipRule="evenodd"></path></svg>
                                    </button>
                                </div>
                            </div>

                            <div id="toast-success" className="hidden w-full max-w-sm px-5 fixed bottom-5 left-0" role="alert">
                                <div className="flex items-center text-gray-700 bg-green-100 rounded-lg shadow p-4">
                                    <div className="inline-flex items-center justify-center flex-shrink-0 w-6 h-8 text-green-500 bg-green-100 rounded-lg">
                                        <svg aria-hidden="true" className="w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clipRule="evenodd"></path></svg>
                                        <span className="sr-only">Check icon</span>
                                    </div>
                                    <div className="mx-3 text-sm font-normal">Message sent successfully!</div>
                                    <button type="button" onClick={() => document.getElementById("toast-success").style.display = "none"} className="ml-auto -mx-1.5 -my-1.5 bg-white text-gray-400 hover:text-gray-900 rounded-lg focus:ring-2 focus:ring-gray-300 p-1.5 hover:bg-gray-100 inline-flex h-8 w-8" data-dismiss-target="#toast-danger" aria-label="Close">
                                        <span className="sr-only">Close</span>
                                        <svg aria-hidden="true" className="w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clipRule="evenodd"></path></svg>
                                    </button>
                                </div>
                            </div>
                        </>
                    )}
                </Await>
            </React.Suspense>
        </>
    );
};
